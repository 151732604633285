import { template as template_bb567d1b387c443da1d95f6c919645af } from "@ember/template-compiler";
const SidebarSectionMessage = template_bb567d1b387c443da1d95f6c919645af(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
