import { template as template_145184b8b67c41c89715d765d5fa9369 } from "@ember/template-compiler";
const FKLabel = template_145184b8b67c41c89715d765d5fa9369(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
