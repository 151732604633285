import { template as template_767b13a0b2b44293af3e554a3d0c4dde } from "@ember/template-compiler";
const WelcomeHeader = template_767b13a0b2b44293af3e554a3d0c4dde(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
