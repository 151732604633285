import { template as template_96d0a7c20a7b4bb19005a515a44540e0 } from "@ember/template-compiler";
const FKText = template_96d0a7c20a7b4bb19005a515a44540e0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
